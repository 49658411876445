body {
  background-color: #282c34;
  color: lightgray;
}

h1 {
  margin: 0;
  color: white;
  font-size: 2em;
}

h2 {
  margin: 0;
  font-size: 1.2em;
  font-style: italic;
}

.header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 12px;
}

.app-description {
  margin: 10px;
}

.controls {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #282c34;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.label-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}